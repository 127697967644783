.form {
  min-height: 600px;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;

  .gradient {
    border-radius: 10px;
    padding: 20px 30px;
    height: 100%;
    min-height: 600px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.95) 30%,
      rgba(0, 0, 0, 0) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .header {
      display: flex;
      flex-direction: row;

      .logo {
        height: 40px;
        margin-right: 15px;
      }
    }

    .content {
      h1 {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 50px;
        color: #888;
      }

      margin-bottom: 50px;
    }

    .footer {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 992px) {
  .form {
    .gradient {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.95) 40%,
        rgba(0, 0, 0, 0) 100%
      );

      .header {
        .logo {
          height: 40px;
        }

        .pretitle {
          font-size: 12px;
        }

        .title {
          font-size: 16px;
        }
      }
    }
  }
}
