.form {
  padding: 20px 30px;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}
