.card {
  overflow-y: hidden;
  min-height: 620px;
  height: 600px;
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .card {
    min-height: 100%;
    height: auto;
    border-radius: 0;
  }
}
