.stepper {
  padding: 10px 30px;
  border-right: 1px solid rgba(34, 41, 47, 0.08);

  .step {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    height: 80px;
    cursor: pointer;

    .icon {
      height: 40px;
      width: 40px;
      min-width: 40px;
      background-color: rgba(186, 191, 199, 0.12);
      color: #babfc7;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }

    .title {
      color: #6e6b7b;
      font-weight: 600;
      margin-bottom: 0px;
      font-size: 13px;
      line-height: 25px;
    }

    .description {
      color: #b9b9c3;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
    }

    &.active {
      .icon {
        background: linear-gradient(118deg, #04bebe, #04bebe);
        box-shadow: 0 0 10px 1px rgba(4, 190, 190, 0.7) !important;
        color: #fff;
      }

      .title {
        color: #04bebe;
      }
    }

    &.completed {
      .icon {
        background-color: rgba(115, 103, 240, 0.12);
        color: #04bebe;
      }

      .title {
        color: #b9b9c3;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .stepper {
    padding: 0 30px;
    border-bottom: 1px solid rgba(34, 41, 47, 0.08);

    .step {
      display: none;

      &.active {
        display: flex;
      }
    }
  }
}