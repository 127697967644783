.header {
  width: 100%;
  border-bottom: 1px solid #dae1e7;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;

  .logo {
    height: 50px;
    margin-right: 15px;
  }

  .pretitle {
    font-weight: 500;
    font-size: 13px;
    color: #888;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
  }
}

@media only screen and (max-width: 992px) {
  .header {
    .logo {
      height: 40px;
    }

    .pretitle {
      font-size: 12px;
    }

    .title {
      font-size: 16px;
    }
  }
}
