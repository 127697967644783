@import "~antd/dist/antd.css";

html,
body {
  font-family: "Montserrat", sans-serif;
  color: #5e5873;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
  color: #5e5873;
}

.main-content {
  background-color: #f8f8f8;
  min-height: 100vh;
  overflow: auto;
}

.status {
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    max-height: 130px;
    margin-bottom: 30px;
  }

  button {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
