.alert {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  > .icon {
    margin-right: 10px;
    font-size: 20px;
    height: 20px;
    margin-top: -2px;
  }

  &.warning {
    padding: 10px 20px;
    font-weight: 600;
    color: #ff9f43 !important;
    background-color: #ffecd9;
    border-color: #ffe2c7;
    border-radius: 10px;
  }
}
