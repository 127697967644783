.ant-btn {
  height: initial;
  border-radius: 5px;
  padding: 10px 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  min-width: 130px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }
}

.ant-btn-primary {
  color: #fff;
  border: none !important;
  background: #04bebe !important;
  transition: box-shadow 0.35s ease;

  &:hover {
    box-shadow: 0 0 10px 1px rgba(4, 190, 190, 0.7) !important;
  }

  &:disabled {
    color: #fff !important;
    background-color: #c7b7d0 !important;
    box-shadow: none !important;
  }
}