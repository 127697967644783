@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("./../assets/fonts/montserrat-v15-latin-300.eot");
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("./../assets/fonts/montserrat-v15-latin-300.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/montserrat-v15-latin-300.woff2") format("woff2"),
    url("./../assets/fonts/montserrat-v15-latin-300.woff") format("woff"),
    url("./../assets/fonts/montserrat-v15-latin-300.ttf") format("truetype"),
    url("./../assets/fonts/montserrat-v15-latin-300.svg#Montserrat")
      format("svg");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./../assets/fonts/montserrat-v15-latin-regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("./../assets/fonts/montserrat-v15-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/montserrat-v15-latin-regular.woff2") format("woff2"),
    url("./../assets/fonts/montserrat-v15-latin-regular.woff") format("woff"),
    url("./../assets/fonts/montserrat-v15-latin-regular.ttf") format("truetype"),
    url("./../assets/fonts/montserrat-v15-latin-regular.svg#Montserrat")
      format("svg");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("./../assets/fonts/montserrat-v15-latin-500.eot");
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("./../assets/fonts/montserrat-v15-latin-500.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/montserrat-v15-latin-500.woff2") format("woff2"),
    url("./../assets/fonts/montserrat-v15-latin-500.woff") format("woff"),
    url("./../assets/fonts/montserrat-v15-latin-500.ttf") format("truetype"),
    url("./../assets/fonts/montserrat-v15-latin-500.svg#Montserrat")
      format("svg");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("./../assets/fonts/montserrat-v15-latin-600.eot");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("./../assets/fonts/montserrat-v15-latin-600.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/montserrat-v15-latin-600.woff2") format("woff2"),
    url("./../assets/fonts/montserrat-v15-latin-600.woff") format("woff"),
    url("./../assets/fonts/montserrat-v15-latin-600.ttf") format("truetype"),
    url("./../assets/fonts/montserrat-v15-latin-600.svg#Montserrat")
      format("svg");
}
