.content {
  flex: 1;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 992px) {
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
